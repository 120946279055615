<template>
  <div>
    <v-form autocomplete="new-password" ref="form" :lazy-validation="lazy">
      <v-text-field
        v-model="formData.name"
        label="Nome"
        name="name"
        prepend-icon="mdi-account"
        type="text"
        :rules="nameRules"
      />
      <date-picker 
        :active_picker="'YEAR'" 
        :rules="required"
        :max="$dayjs().subtract(18, 'year').format('YYYY-MM-DD')" 
        label="Data de Nascimento"
        @datePicked="handleDatePicked" 
      />
      <v-text-field
        v-model="formData.state"
        label="Estado"
        name="state"
        prepend-icon="mdi-map-marker"
        type="text"
        :rules="required"
      />
      <v-text-field
        v-model="formData.city"
        label="Cidade"
        name="city"
        prepend-icon="mdi-map-marker"
        type="text"
        :rules="required"
      />
      <v-text-field
        v-model="formData.phone"
        label="Telefone para contato"
        name="phone"
        prepend-icon="mdi-phone"
        :rules="required"
        v-mask="formData.phone ? formData.phone.toString().length >= 15 ? '(##) #####-####' : '(##) ####-####' : ''"
      />
      <v-text-field
        v-model="formData.email"
        label="Email"
        name="email"
        prepend-icon="mdi-email"
        type="text"
        :rules="emailRules"
        validate-on-blur
      />
      <v-text-field
        v-model="formData.password"
        id="password"
        label="Senha"
        name="password"
        prepend-icon="mdi-lock-open"
        type="password"
        :rules="[
          ruleRequired('Defina uma senha'),
          ruleMinLen(6),
          ruleMaxLen(30)
        ]"
      />
      <v-text-field
        v-model="formData.password_confirmation"
        id="password_confirmation"
        label="Confirmação de senha"
        name="password_confirmation"
        prepend-icon="mdi-lock"
        type="password"
        :rules="[
          ruleRequired('Confirme a senha'),
          ruleMinLen(6),
          ruleMaxLen(30),
          ruleMatch(formData.password)
        ]"
      />
      <v-text-field
        v-model="formData.curriculum_link"
        label="Curriculum Lattes"
        name="curriculum_link"
        prepend-icon="mdi-link"
        type="text"
        :rules="requiredUrl"
      />
    </v-form>
  </div>
</template>

<script>
import validations from "@/mixins/validations";
import { VueMaskDirective } from 'v-mask'

export default {
  directives: {
    mask: VueMaskDirective,
  },
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  mixins: [validations],
  data() {
    return {
      lazy: false,
      valid: true,
    };
  },
  methods: {
    validateForm() {
      return this.$refs.form.validate();
    },
    handleDatePicked(date){
      this.formData.birthdate = this.$dayjs(date).format('YYYY/MM/DD')
    },
  }
};
</script>
